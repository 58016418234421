<template>
    <div class="hamburger">
        <span
            class="hamburger__bun"
            aria-hidden="true"
        />
        <span
            class="hamburger__bun"
            aria-hidden="true"
        />
        <span
            class="hamburger__bun"
            aria-hidden="true"
        />
    </div>
</template>

<style lang="less" src="./hamburger.less"></style>
