<template>
    <NuxtLink
        :to="localePath('/')"
        class="sf-tagline"
    >
        {{ t('tagline') }}
    </NuxtLink>
</template>

<script setup>
const { t } = useI18n({
    useScope: 'local'
});

const localePath = useLocalePath();
</script>

<style src="./tagline.less" lang="less"></style>

<i18n lang="json">
{
    "nl": {
        "tagline": "Stimuleringsfonds creatieve industrie"
    },
    "en": {
        "tagline": "Creative industries fund nl"
    }
}
</i18n>
