<template>
    <nav class="sf-menu-actions">
        <ul>
            <li>
                <SfLanguageSwitch />
            </li>

            <li>
                <a
                    href="https://aanvragen.stimuleringsfonds.nl/register/"
                    target="_blank"
                >{{ t('login') }}</a>
            </li>
        </ul>
    </nav>
</template>

<script setup>
import SfLanguageSwitch from '~/patterns/atoms/language-switch/language-switch';

const { t } = useI18n({
    useScope: 'local'
});
</script>

<style src="./menu-actions.less" lang="less"></style>

<i18n lang="json">
{
    "nl": {
        "login": "Inloggen"
    },
    "en": {
        "login": "Login"
    }
}
</i18n>
