<template>
    <SfModal
        :is-visible="isOpen"
        data-modal-name="video"
        @close="modalStore.close"
    >
        <template
            v-if="modalStore.data && modalStore.data.title"
            #title
        >
            {{ modalStore.data.title }}
        </template>

        <template
            v-if="modalStore.data && modalStore.data.vimeoUrl"
            #default
        >
            <sf-vimeo-embed
                :title="modalStore.data.title"
                :url="modalStore.data.vimeoUrl"
                :accessible-url="modalStore.data.accessibleVimeoUrl"
            />
        </template>
    </SfModal>
</template>

<script>
import { useModalStore } from '~/store/modal';

import SfModal from '~/patterns/organisms/modal/modal';
import SfVimeoEmbed from '~/patterns/molecules/vimeo-embed/vimeo-embed';

export default {
    components: {
        SfModal,
        SfVimeoEmbed
    },

    data() {
        return {
            modalStore: useModalStore()
        };
    },

    computed: {
        isOpen() {
            return this.modalStore.activeModal === 'video';
        }
    }
};
</script>
