<template>
    <footer
        v-if="footer"
        id="footer"
        class="sf-footer"
        tabindex="-1"
    >
        <SfPageContainer
            v-if="footer.footerLists && footer.footerLists.length"
            class="sf-footer__container"
        >
            <!--sf-menu-list-->
            <div
                v-for="(footerList, index) in footer.footerLists"
                :key="`list_${index}`"
            >
                <sf-footer-menu-list
                    :list="footerList"
                />
            </div>
            <sf-social-links class="sf-footer__social-links" />
            <!--/sf-menu-list-->
        </SfPageContainer>

        <a
            v-if="footer.annualReport && footer.annualReport.url"
            :href="footer.annualReport.url"
            class="sf-footer__jaarverslag"
        >
            <SfPageContainer data-collapsed="true">
                <h3 v-if="footer.annualReport.title">
                    {{ footer.annualReport.title }}
                    <sf-icon icon="arrow-up" />
                </h3>

                <sf-image
                    v-if="footer.annualReport.image"
                    :image="footer.annualReport.image"
                />
            </SfPageContainer>
        </a>
    </footer>
</template>

<script setup>
import SfPageContainer from '~/patterns/organisms/page-container/page-container';
import SfFooterMenuList from '~/patterns/molecules/menu-list/presets/footer-menu.vue';
import SfSocialLinks from '~/patterns/molecules/social-links/social-links.vue';
import SfImage from '~/patterns/atoms/image/image.vue';
import SfIcon from '~/patterns/atoms/icon/icon.vue';

const globalsStore = useGlobalsStore();
const { footer } = storeToRefs(globalsStore);
</script>

<style lang="less" src="./footer.less" />
