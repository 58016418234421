<template>
    <SfMenuList class="footer-menu">
        <template
            v-if="list.sfTitle"
            #title
        >
            <h3>{{ list.sfTitle }}</h3>
        </template>

        <template
            v-if="list.listItems && list.listItems.length"
            #custom
        >
            <li
                v-for="(listItem, indexTwo) in list.listItems"
                :key="`list-item_${indexTwo}`"
            >
                <template v-if="listItem.externalLink && isExternalLink(listItem.externalLink)">
                    <a
                        :href="listItem.externalLink"
                        target="_blank"
                    >
                        {{ listItem.label }}
                    </a>

                    <SfIcon icon="external-link" />
                </template>

                <NuxtLink
                    v-else-if="listItem.entry && listItem.entry[0]"
                    :to="urlToPath(listItem.entry[0].url)"
                >
                    {{ listItem.label }}
                </NuxtLink>

                <NuxtLink
                    v-else-if="listItem.externalLink && !isExternalLink(listItem.externalLink)"
                    :to="getInternalLink(listItem.externalLink)"
                >
                    {{ listItem.label }}
                </NuxtLink>
            </li>
        </template>
    </SfMenuList>
</template>

<script setup>
import SfMenuList from '~/patterns/molecules/menu-list/menu-list.vue';
import SfIcon from '~/patterns/atoms/icon/icon.vue';

defineProps({
    list: {
        type: Object,
        default: null
    }
});

const { public: { baseURL } } = useRuntimeConfig();

function isExternalLink(link) {
    return !link.startsWith(baseURL);
}

function getInternalLink(link) {
    // Strip leading and trailing slashes, as the graphql query doesn't
    // work with a leading slash
    const appURL = baseURL.replace(/^\/|\/$/g, '');
    return link.replace(appURL, '');
}
</script>

<i18n lang="json">
{
    "nl": {
        "grants": "Subsidies",
        "info": "Info",
        "search": "Zoek",
        "discover": "Ontdek"
    },
    "en": {
        "grants": "Grants",
        "info": "Info",
        "search": "Search",
        "discover": "Discover"
    }
}
</i18n>

<style src="./footer-menu.less" lang="less"></style>
