<template>
    <div
        v-if="showLanguageSwitch"
        class="sf-language-switch"
    >
        <a
            v-if="showLocaleNl"
            :href="localePathNl"
            :data-active="locale === 'nl'"
            lang="nl"
            aria-label="Bekijk pagina in het Nederlands"
        >
            nl
        </a>

        <SfIcon icon="chevron-right" />

        <a
            v-if="showLocaleEn"
            :href="localePathEn"
            :data-active="locale === 'en'"
            lang="en"
            aria-label="View page in English"
        >
            eng
        </a>
    </div>
</template>

<script setup>
import getTranslations from '~/graphql/queries/getTranslations.graphql';

import SfIcon from '~/patterns/atoms/icon/icon.vue';

const ALWAYS_SHOW_LANGUAGE_SWITCH = [
    '/zoek',
    '/search',
    '/en/search',
    '/home',
    '/',
    '/subsidies',
    '/discover',
    '/en/discover',
    '/toekenningen',
    '/en/grants-issued',
    '/en/grants',
    '/nieuws',
    '/en/news',
    '/agenda',
    '/en/agenda',
    '/dossiers',
    '/en/dossiers',
    '/projecten',
    '/en/projects',
    '/publicaties',
    '/en/publications'
];

const { locale } = useI18n();

const switchLocalePath = useSwitchLocalePath();

const route = useRoute();

const path = computed(() => {
    switch (true) {
    case Array.isArray(route.params.slug):
        return route.params.slug?.[0] ?? route.path;
    default:
        return route.params.slug ?? route.path;
    }
});

const { variables } = useCraftGraphql();
const { data } = await useAsyncQuery({
    query: getTranslations,
    variables
});

const currentEntry = computed(() => data.value?.entry ?? {});

const translations = computed(() => {
    const translationsObj = {};

    if (!currentEntry.value || !currentEntry.value.translations || !currentEntry.value.translations.length > 0) {
        return translationsObj;
    }

    currentEntry.value.translations.forEach((translation) => {
        translationsObj[translation.language] = translation;
    });

    return translationsObj;
});

const showLanguageSwitch = computed(() => {
    if (ALWAYS_SHOW_LANGUAGE_SWITCH.includes(path.value)) {
        return true;
    }

    return !!(translations.value?.nl) || !!(translations.value?.en);
});

function showLocale(localeArg) {
    // Make an exception for pages that have no entry
    if (ALWAYS_SHOW_LANGUAGE_SWITCH.includes(path.value)) {
        return true;
    }

    return locale.value === localeArg || !!(translations.value?.[localeArg]);
}

function getPathForLocale(localeArg) {
    // Option 1: the same locale is requested. Show the current url.
    if (localeArg === locale.value) {
        return path.value;
    }

    // Option 2: There is a translated URL based on the dynamic entry. Use it.
    if (translations?.value?.[localeArg]?.url) {
        return urlToPath(translations.value[localeArg].url);
    }

    // Option 3: Fall back on the i18n path
    return switchLocalePath(localeArg);
}

const showLocaleNl = computed(() => showLocale('nl'));
const showLocaleEn = computed(() => showLocale('en'));
const localePathNl = computed(() => getPathForLocale('nl'));
const localePathEn = computed(() => getPathForLocale('en'));
</script>

<style src="./language-switch.less" lang="less" />
