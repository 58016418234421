<template>
    <Transition name="fade">
        <nav
            v-show="isVisible"
            ref="menu"
            tabindex="0"
            role="dialog"
            class="sf-menu-overlay"
            @keydown.esc="uiStore.closeMenu"
        >
            <div class="sf-menu-overlay__body">
                <SfEscapeHatch />
                <SfMainMenuList />
                <SfMenuActions />
                <SfTagline />
            </div>
        </nav>
    </Transition>
</template>

<script setup>
import SfMainMenuList from '~/patterns/molecules/menu-list/presets/main-menu.vue';
import SfMenuActions from '~/patterns/molecules/menu-actions/menu-actions';
import SfEscapeHatch from '~/patterns/atoms/escape-hatch/escape-hatch';
import SfTagline from '~/patterns/atoms/tagline/tagline';
import { useUiStore } from '~/store/ui';

const props = defineProps({
    isVisible: {
        type: Boolean,
        default: false
    }
});

const uiStore = useUiStore();

const menu = ref(null);

watch(() => props.isVisible, () => {
    if (props.isVisible) {
        nextTick(() => {
            if (menu.value) {
                menu.value.focus();
            }
        });
    }
});
</script>

<style lang="less" src="./menu-overlay.less" />
