<template>
    <SfModal
        :is-visible="isOpen"
        data-modal-name="content"
        @close="modalStore.close"
    >
        <template
            v-if="modalStore.data && modalStore.data.title"
            #title
        >
            {{ modalStore.data.title }}
        </template>

        <template
            v-if="modalStore.data && modalStore.data.subTitle"
            #subTitle
        >
            {{ modalStore.data.subTitle }}
        </template>

        <template
            v-if="modalStore.data && modalStore.data.content"
            #default
        >
            <article v-html="modalStore.data.content" /> <!-- eslint-disable-line -->
        </template>

        <template
            v-if="modalStore.data && modalStore.data.footer"
            #footer
        >
            <div v-html="modalStore.data.footer" /> <!-- eslint-disable-line -->
        </template>
    </SfModal>
</template>

<script>
import { useModalStore } from '~/store/modal';

import SfModal from '~/patterns/organisms/modal/modal';

export default {
    components: {
        SfModal
    },

    data() {
        return {
            modalStore: useModalStore()
        };
    },

    computed: {
        isOpen() {
            return this.modalStore.activeModal === 'content';
        }
    }
};
</script>
