<template>
    <div
        class="layout"
        :data-collapsed="isLayoutCollapsed ? 'true' : 'false'"
        :data-animation-state="animationState"
    >
        <SfSkipLinks />

        <div
            class="layout__logo"
            dark-theme-allowed
        >
            <ClientOnly>
                <TransitionGroup name="cross-fade">
                    <template v-if="$i18n.locale === 'nl'">
                        <SfEscapeHatchDiscoverNl
                            v-if="animationState === 'discover'"
                            key="1"
                        />
                        <!-- <sf-escape-hatch-foldout-nl v-else-if="animationState === 'foldout'"
                            key="2"
                        /> -->
                        <SfEscapeHatchBlockNl
                            v-else-if="animationState === 'static' && animationIndex === 1"
                            key="3"
                        />
                        <SfEscapeHatchCurvyNl
                            v-if="animationState === 'static' && animationIndex === 2"
                            key="4"
                        />
                        <SfEscapeHatchPixelNl
                            v-if="animationState === 'static' && animationIndex === 3"
                            key="5"
                        />
                        <SfEscapeHatchShiverNl
                            v-if="animationState === 'static' && animationIndex === 4"
                            key="6"
                        />
                        <SfEscapeHatchSpikeNl
                            v-if="animationState === 'static' && animationIndex === 5"
                            key="7"
                        />
                    </template>

                    <template v-else-if="$i18n.locale === 'en'">
                        <SfEscapeHatchDiscoverEn
                            v-if="animationState === 'discover'"
                            key="8"
                        />
                        <!-- <sf-escape-hatch-foldout-en v-else-if="animationState === 'foldout'"
                            key="9"
                        /> -->
                        <SfEscapeHatchBlockEn
                            v-else-if="animationState === 'static' && animationIndex === 1"
                            key="10"
                        />
                        <SfEscapeHatchCurvyEn
                            v-if="animationState === 'static' && animationIndex === 2"
                            key="11"
                        />
                        <SfEscapeHatchPixelEn
                            v-if="animationState === 'static' && animationIndex === 3"
                            key="12"
                        />
                        <SfEscapeHatchShiverEn
                            v-if="animationState === 'static' && animationIndex === 4"
                            key="13"
                        />
                        <SfEscapeHatchSpikeEn
                            v-if="animationState === 'static' && animationIndex === 5"
                            key="14"
                        />
                    </template>
                </TransitionGroup>
            </ClientOnly>

            <SfEscapeHatch />
        </div>

        <NuxtLink
            :to="localePath('/')"
            class="layout__logo-link"
            :aria-hidden="true"
            tabindex="-1"
        />

        <div
            class="layout__tagline"
            dark-theme-allowed
        >
            <SfTagline />
        </div>

        <Transition
            name="fade"
            appear
        >
            <div
                class="layout__navigation"
                :class="{
                    'layout__navigation--hidden': !uiStore.isNavigationVisible
                }"
            >
                <SfMainMenuList class="sf-menu-list--inline" />
            </div>
        </Transition>

        <SfMenuActions
            class="layout__actions"
            dark-theme-allowed
        />

        <main
            id="main"
            class="layout__body"
            tabindex="-1"
        >
            <slot />
        </main>

        <!-- Mobile menu -->

        <Teleport to="body">
            <div
                class="layout__menu-toggle"
                dark-theme-allowed
            >
                <SfButton
                    class="sf-button--menu-toggle"
                    :title="uiStore.isMenuOpen ? t('menu-toggle.open-title') : t('menu-toggle.closed-title')"
                    @click="uiStore.toggleMenu"
                >
                    <SfHamburger
                        :data-toggled="uiStore.isMenuOpen"
                    />
                </SfButton>
            </div>
        </Teleport>

        <SfMenuOverlay :is-visible="uiStore.isMenuOpen" />

        <SfFooter class="layout__footer" />

        <SfVideoModal />
        <SfContentModal />

        <Teleport to="body">
            <ClientOnly>
                <LazyCookieBar />
            </ClientOnly>
        </Teleport>

        <Transition
            name="fade"
            appear
        >
            <div
                v-if="uiStore.isCollapsed"
                class="layout__back-to-top"
                dark-theme-allowed
            >
                <SfButton
                    class="dn-button--clean"
                    @click="scrollToTop"
                >
                    <SfIcon icon="arrow-up" />
                    <span class="sr-only">{{ $t('back-to-top') }}</span>
                </SfButton>
            </div>
        </Transition>

        <ClientOnly>
            <NuxtLoadingIndicator
                :height="2"
                color="#000"
            />
        </ClientOnly>
    </div>
</template>

<script setup>
import { throttle } from 'lodash-es';

import SfMenuOverlay from '~/patterns/organisms/menu-overlay/menu-overlay.vue';
import SfFooter from '~/patterns/organisms/footer/footer.vue';
import SfVideoModal from '~/patterns/organisms/modal/presets/video';
import SfContentModal from '~/patterns/organisms/modal/presets/content';
import SfMainMenuList from '~/patterns/molecules/menu-list/presets/main-menu.vue';
import SfMenuActions from '~/patterns/molecules/menu-actions/menu-actions';

import SfEscapeHatch from '~/patterns/atoms/escape-hatch/escape-hatch';

import SfEscapeHatchDiscoverNl from '~/patterns/atoms/escape-hatch-animated/s/escape-hatch-animated-discover';
import SfEscapeHatchBlockNl from '~/patterns/atoms/escape-hatch-animated/s/escape-hatch-animated-block';
import SfEscapeHatchCurvyNl from '~/patterns/atoms/escape-hatch-animated/s/escape-hatch-animated-curvy';
import SfEscapeHatchPixelNl from '~/patterns/atoms/escape-hatch-animated/s/escape-hatch-animated-pixel';
import SfEscapeHatchShiverNl from '~/patterns/atoms/escape-hatch-animated/s/escape-hatch-animated-shiver';
import SfEscapeHatchSpikeNl from '~/patterns/atoms/escape-hatch-animated/s/escape-hatch-animated-spike';

import SfEscapeHatchDiscoverEn from '~/patterns/atoms/escape-hatch-animated/c/escape-hatch-animated-discover';
import SfEscapeHatchBlockEn from '~/patterns/atoms/escape-hatch-animated/c/escape-hatch-animated-block';
import SfEscapeHatchCurvyEn from '~/patterns/atoms/escape-hatch-animated/c/escape-hatch-animated-curvy';
import SfEscapeHatchPixelEn from '~/patterns/atoms/escape-hatch-animated/c/escape-hatch-animated-pixel';
import SfEscapeHatchShiverEn from '~/patterns/atoms/escape-hatch-animated/c/escape-hatch-animated-shiver';
import SfEscapeHatchSpikeEn from '~/patterns/atoms/escape-hatch-animated/c/escape-hatch-animated-spike';

import SfHamburger from '~/patterns/atoms/hamburger/hamburger';
import SfButton from '~/patterns/atoms/button/button';
import SfIcon from '~/patterns/atoms/icon/icon';
import SfTagline from '~/patterns/atoms/tagline/tagline';
import SfSkipLinks from '~/patterns/atoms/skip-links/skip-links';

import GetGeneralQuery from '~/graphql/queries/global-sets/getGeneral.graphql';

import { getLogoAnimationStateByRoute, getLogoStaticAnimationIndex } from '~/helpers/animations';

const globalsStore = useGlobalsStore();
const uiStore = useUiStore();
const { isLayoutCollapsed, isMotionReduced } = storeToRefs(uiStore);

const route = useRoute();

const { variables } = useDefaultVariables();
const { data } = await useAsyncQuery({
    query: GetGeneralQuery,
    variables
});

watchEffect(() => {
    globalsStore.setGeneral(data.value?.globalSet ?? {});
});

const { t } = useI18n({
    useScope: 'local'
});

const localePath = useLocalePath();

const animationState = useState('animationState', () => getLogoAnimationStateByRoute(route, isMotionReduced));
const animationIndex = useState('animationIndex', () => getLogoStaticAnimationIndex(1));

const throttledUpdate = ref(null);

watch(() => route.fullPath, () => {
    animationState.value = getLogoAnimationStateByRoute(route, isMotionReduced);
    animationIndex.value = getLogoStaticAnimationIndex(animationIndex.value);
});

onMounted(() => {
    updateScrollPosition();
    initScrollWatcher();
    setMotionReduced();
});

onUnmounted(() => {
    destroyScrollWatcher();
});

const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
};

const initScrollWatcher = () => {
    throttledUpdate.value = throttle(updateScrollPosition, 50);

    window.addEventListener('scroll', throttledUpdate.value, false);
};

const destroyScrollWatcher = () => {
    window.removeEventListener('scroll', throttledUpdate.value, false);
};

const updateScrollPosition = () => {
    const scrollTop = window.scrollY || document.documentElement.scrollTop;

    uiStore.updateScrollPosition(scrollTop);
};

const setMotionReduced = () => {
    if (window.matchMedia('(prefers-reduced-motion: reduce)').matches) {
        uiStore.setMotionReduced(true);
    }
};
</script>

<style lang="less" src="./layout.less"></style>

<i18n lang="json">
{
    "nl": {
        "menu-toggle.closed-title": "Open menu",
        "menu-toggle.open-title": "Sluit menu",
        "back-to-top": "Terug naar boven"
    },
    "en": {
        "menu-toggle.closed-title": "Open menu",
        "menu-toggle.open-title": "Close menu",
        "back-to-top": "Back to top"
    }
}
</i18n>
