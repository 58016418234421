<template>
    <img
        v-if="image"
        :src="image.url"
        :alt="image.alt || ''"
        loading="lazy"
    >
</template>

<script>
export default {
    props: {
        image: {
            type: Object,
            default: null
        }
    }
};
</script>

<style src="./image.less" lang="less"></style>
