import { throttle } from 'lodash-es';

export default {
    data() {
        return {
            animation: null
        };
    },

    mounted() {
        setTimeout(() => {
            document.addEventListener('scroll', throttle(this.handleScroll, 1.666666)); // Throttle to 60fps
        }, 300);
    },

    beforeDestroy() {
        document.removeEventListener('scroll', throttle(this.handleScroll, 1.666666));
    },

    methods: {
        handleScroll() {
            const mapScrollDepthToDuration = window.scrollY / (document.body.clientHeight - 1000) * this.animationTime; // Calculate animation time based on scrolldepth
            this.animation.time(mapScrollDepthToDuration);
        }
    }
};
