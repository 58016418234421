<template>
    <div class="sf-skip-links">
        <a
            href="#main"
            class="dn-button sf-button"
        >
            {{ t('skip-link-main') }}
        </a>

        <a
            href="#footer"
            class="dn-button sf-button"
        >
            {{ t('skip-link-footer') }}
        </a>
    </div>
</template>

<script setup>
const { t } = useI18n();
</script>

<style src="./skip-links.less" lang="less"></style>

<i18n lang="json">
{
    "nl": {
        "skip-link-main": "Ga naar de hoofdinhoud",
        "skip-link-footer": "Ga naar de footer"
    },
    "en": {
        "skip-link-main": "Jump to main content",
        "skip-link-footer": "Jump to footer"
    }
}
</i18n>
