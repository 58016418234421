export function getLogoAnimationStateByRoute($route, isMotionReduced = false) {
    const foldoutRoutes = [
        'articles-slug___nl',
        'dossiers-slug___nl',
        'events-slug___nl',
        'grants-slug___nl',
        'news-slug___nl',
        'projects-slug___nl',
        'articles-slug___en',
        'dossiers-slug___en',
        'events-slug___en',
        'grants-slug___en',
        'news-slug___en',
        'projects-slug___en'
    ];

    if (!toValue(isMotionReduced) && ($route.name === 'discover___nl' || $route.name === 'index___nl' || $route.name === 'discover___en' || $route.name === 'index___en')) {
        return 'discover';
    }

    if (foldoutRoutes.includes($route.name)) {
        return 'foldout';
    }

    return 'static';
}

export function getLogoStaticAnimationIndex(animationIndex) {
    let newIndex = animationIndex;

    while (newIndex === animationIndex) { // Roll a new index until it's different from previous
        newIndex = Math.ceil(Math.random() * 5);
    }

    return newIndex;
}
