
<template>
    <DnModal
        v-bind="$attrs"
        class="sf-modal"
    >
        <header
            v-if="title || !!$slots.title"
            class="sf-modal__header"
        >
            <h1 class="sf-modal__title"><slot name="title">{{ title }}</slot></h1>
            <h2
                v-if="!!$slots.subTitle"
                class="sf-modal__subtitle"
            >
                <slot name="subTitle" />
            </h2>
        </header>

        <div class="sf-modal__body"><slot /></div>

        <div
            v-if="!!$slots.footer"
            class="sf-modal__footer"
        >
            <slot name="footer" />
        </div>

        <template v-slot:close-button>
            <button
                class="sf-modal__close"
                type="button"
                :aria-label="$t('close-modal')"
                @click="$emit('close')"
            >
                <DnIcon icon="cross" />
            </button>

            <div class="sf-modal__close-background" />
        </template>
    </DnModal>
</template>

<script setup>
import DnModal from '@digitalnatives/modal';
import DnIcon from '~/patterns/atoms/icon/icon.vue';

defineProps({
    title: {
        type: String,
        default: null
    }
});

defineEmits(['close']);
</script>

<style src="./modal.less" lang="less"></style>

<i18n>
{
    "en": {
        "close-modal": "Close pop-up"
    },
    "nl": {
        "close-modal": "Pop-up sluiten"
    }
}
</i18n>
