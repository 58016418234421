<template>
    <NuxtLink
        :to="localePath('/')"
        class="sf-escape-hatch"
    >
        <img
            v-if="locale === 'en'"
            :src="LogoEn"
            alt="Logo of Creative Industries Fund NL"
        >

        <img
            v-else
            :src="LogoNl"
            alt="Logo van Stimuleringsfonds Creatieve Industrie"
        >
    </NuxtLink>
</template>

<script setup>
import LogoNl from '~/assets/img/logos/logo-nl.svg';
import LogoEn from '~/assets/img/logos/logo-en.svg';

const { locale } = useI18n();
</script>

<style src="./escape-hatch.less" lang="less"></style>
